import type {
  City,
  ModulePlace,
  Order,
  Reply,
  StatusElement,
  StatusService,
  Travels,
} from '@/types/api.js'
import type { Place } from '@/types/place.js'
import { defineStore } from 'pinia'

export const useGlobal = defineStore('globalStore', () => {
  // Data to use globalStore

  const fiscalResponsibility = ref([
    { label: 'Gran contribuyente', value: 'O-13' },
    { label: 'Autorretenedor', value: 'O-15' },
    { label: 'Agente de retención IVA', value: 'O-23' },
    { label: 'Régimen simple de tributación', value: 'O-47' },
    { label: 'No responsable de IVA', value: 'R-99-PN' },
  ])
  const typeLegalCommerce = ref([
    { label: 'Persona Jurídica', value: '1' },
    { label: 'Persona Natural', value: '2' },
  ])

  const nuxtApp = useNuxtApp()
  // Place
  const place = ref<Place>()
  const module = ref<{ name: string, id: number }>({ id: 0, name: '' })
  // Orders
  const orders = ref<Order[]>([])
  const dateSearchOrders = ref('')
  function OrdersState(value: [Order]) {
    orders.value = value
  }
  function OrderAddState(orderSend: Order) {
    const ordersLocal = orders.value
    const index = ordersLocal.findIndex(order => order.id === orderSend.id)
    if (index !== -1) {
      orders.value[index] = { ...orders.value[index], ...orderSend }
    }
    else {
      ordersLocal.unshift(orderSend)
      orders.value = ordersLocal
    }
  }
  function dateSearchOrderState(value: string) {
    dateSearchOrders.value = value
  }

  // Travels
  const travels = ref<Travels[]>([])
  const dateSearchTravels = ref('')
  function travelsState(value: Travels[]) {
    travels.value = value
  }
  function dateSearchTravelState(value: string) {
    dateSearchTravels.value = value
  }

  // Cities
  const cities = ref<City[]>([])
  function CityState(value: City[]) {
    cities.value = value
  }

  // Statuses
  const statuses = ref<StatusElement[]>([])
  function StatusesState(value: StatusElement[]) {
    statuses.value = value
  }
  const statusesRunner = ref<StatusService[]>([])
  function StatusesRunnerState(value: StatusService[]) {
    statusesRunner.value = value
  }

  // Modales
  const createModalRef = ref(false)
  const alertModelRef = ref(false)
  const modalOrderDetail = ref(false)
  const modalTravelDetail = ref(false)
  const modalCheckOutDetail = ref(false)
  const openFormData = ref(false)

  function state(value: boolean) {
    createModalRef.value = value
  }

  function modalOrderDetailState(value: boolean) {
    modalOrderDetail.value = value
  }

  function modalTravelDetailState(value: boolean) {
    modalTravelDetail.value = value
  }

  function modalCheckOutDetailState(value: boolean) {
    modalCheckOutDetail.value = value
  }

  // Alert
  const alertObjectRef = ref({
    title: '' as string,
    message: '' as string,
    type: '' as string,
  })
  function alertState(value: boolean) {
    alertModelRef.value = value
  }
  function alertDataState(title: string, message: string, type: 'success' | 'error') {
    alertObjectRef.value = {
      title,
      message,
      type,
    }
  }

  // Table
  const createLoadingTableRef = ref(false)

  // Place modules
  const placeModulesRef = ref<ModulePlace[]>([])

  async function getPlaceModules() {
    try {
      if (place.value && place.value.id) {
        const responsePlaceModules = await nuxtApp.$urlEfici.post<Reply<{ modules: ModulePlace[] }>>(`${nuxtApp.$endpoint.modules.getByPlace.url}${place.value.id}`)

        if (responsePlaceModules.data.error)
          throw new Error('Modulos no encontrados', { cause: 'Problemas del servidor' })

        const placeModules = responsePlaceModules.data.data.modules

        placeModulesRef.value = placeModules
      }
    }
    catch (e) {
      placeModulesRef.value = []
      return e
    }
  }
  return {
    // globals
    fiscalResponsibility,
    module,
    typeLegalCommerce,
    place,
    orders,
    dateSearchOrders,
    OrdersState,
    OrderAddState,
    dateSearchOrderState,
    cities,
    CityState,
    statuses,
    StatusesState,
    statusesRunner,
    StatusesRunnerState,

    // Travels
    travels,
    dateSearchTravels,
    travelsState,
    dateSearchTravelState,

    // Modales
    createModalRef,
    modalOrderDetail,
    modalTravelDetail,
    modalCheckOutDetail,
    state,
    modalOrderDetailState,
    modalTravelDetailState,
    modalCheckOutDetailState,
    openFormData,

    // Alert
    alertModelRef,
    alertObjectRef,
    alertState,
    alertDataState,

    // Table
    createLoadingTableRef,

    // Places Modules
    placeModulesRef,
    getPlaceModules,
  }
})
